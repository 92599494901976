
import HubSpotForm from 'pages/hubspotform';

import './styles/integration-partner-form.scss';
const IntegrationPartnerForm = () => {
  return (
    <HubSpotForm></HubSpotForm>
  );
};

export default IntegrationPartnerForm;