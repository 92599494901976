import { useEffect } from 'react';

export default function HubSpotForm() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region:'na1',
          portalId:'7988116',
          formId:'fb9c5ebb-eb66-4e72-a285-d781f2f776ce',
        });
      }
    });
  }, []);

  return (
    <div id="hubspotForm" className="hubspotForm"></div>
  );
}